<script setup lang="ts">
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import type { SbStepperItemStoryblok } from '~/types/storyblok'

const props = defineProps<{ blok: SbStepperItemStoryblok, stepIndex: number }>()

const renderedDescription = computed(() => renderRichText(props.blok.description))
</script>

<template>
  <li
    class="flex min-w-[300] max-w-[330px] gap-x-5 before:text-[56px] before:font-highlighted before:leading-[56px] before:text-brand/40 before:content-[attr(stepIndex)]"
    :stepIndex="`${stepIndex}.`"
  >
    <div v-editable="blok">
      <CfgTypography
        v-if="renderedDescription"
        :html="renderedDescription"
        :size="CfgTypographySizes.md"
        tag-name="div"
      />
    </div>
  </li>
</template>
